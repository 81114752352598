@media only screen and (min-width : 1024px) {
.archive__item-excerpt {
  margin-top: 0;
  font-size:0.79em!important;

}
.btn, #goog-wm-sb {
    font-size: .79em!important;
}
.page__related {
    float: none!important; 
    width: 100%!important;
}
.grid__item .archive__item-teaser {
 max-height: none!important;
}
li > p {
    font-size: 1em!important;
}

li li{
    font-size: 1em !important
}
}

.btn--light-outline {
    background: #37627bc7!important;
	text-shadow: 1px 1px 4px rgba(0,0,0,0.5)!important;
}
.page__lead {
    text-shadow: 2px 2px 1px #464646!important;
}
.page__hero--overlay .page__title, .page__hero--overlay .page__meta, .page__hero--overlay .comment__date, .page__hero--overlay .page__lead, .page__hero--overlay .btn, .page__hero--overlay #goog-wm-sb {
    color: #fff;
    text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.83)!important;
}

.layout--splash .archive__item-title {
font-size: 19px;	
color: #4e80a3
}
.layout--splash #main > article > section > div:nth-child(2) .archive__item-title {
text-transform: uppercase;	
}
.layout--splash .archive__item-teaser img {
    width: 132px!important;
}
.layout--splash .archive__item {
    text-align: center;
}
.layout--splash .btn--inverse {
    background-color: #d5874c!important;
    color: #ffffff!important;
    text-shadow: 1px 1px 1px #000;
}
.layout--splash #main > article > section > div:nth-child(3) .btn--inverse {
    background: #dfeffb!important;
    color: #000!important;
    text-shadow: 1px 1px 1px #ddd;
    font-weight: 700!important;
}
.layout--splash #main > article > section > div:nth-child(3) {
    clear: both;
    margin-bottom: 2em;
    border-bottom: 1px solid #f2f3f3;
    background: #4c7ea3;
    color: #fff;
	padding: 20px 0;
}
.layout--splash #main > article > section > div:nth-child(3) img {
width: 375px!important;
}
.layout--splash .feature__item--left .archive__item-teaser {
margin-bottom: unset!important;
}
.layout--splash #main > article > section > div:nth-child(4) {
margin-top:3em!important;
	
}
.layout--splash #main > article > section > div:nth-child(4) img {
width: 375px!important;
}
.layout--splash #main > article > section > div:nth-child(4) .archive__item {
    text-align: left;
	 float: none!important;
}
.layout--splash #main > article > section > div:nth-child(4) li {
list-style-type:none;
padding: 10px 0;
}
.layout--splash #main > article > section > div:nth-child(4) ul {
padding:0;
}
.layout--splash #main > article > section > div:nth-child(4) .archive__item-title {color:#000;}


.layout--splash #main > article > section > div:nth-child(4) .btn--inverse {
    background-color: #97c2ff !important;
}
.layout--splash svg:not(:root).svg-inline--fa {
    color: #4d7fa3;
}

.layout--splash #main > article > section > div:nth-child(5) img {
    width: 531px !important;
}
.sim2 {
    background-color: #f2f8fb;
    margin: .5em 0;
    padding: 1em 4% 1px 3em;
    padding-left: calc(2em + 2%);
    border-radius: 15px;
}
.sim3 {
    background-color: #fbf2f4;
    margin: .5em 0;
    padding: 1em 4% 1px 3em;
    padding-left: calc(2em + 2%);
    border-radius: 15px;
}
.sim2 svg {
    color: #3a5898;
    font-size: 18px;
}
.num {
    counter-reset: list 0;
}
.num > li {
    border-bottom: 1px solid #e2e2e2;
    list-style: outside none none;
    padding: 5px 5px 10px 30px;
    position: relative;
}
.num li:before {
    background: #3a5898;
    left: -1px;
    position: absolute;
    text-align: center;
    top: 7px;
    color: #fff;
    content: counter(list,decimal) " ";
    counter-increment: list;
    font-weight: bold;
    width: 26px;
    border-radius: 50%;
    height: 26px;
}
.nev {
    color: #888;
}
.img-tit {
    text-align: center;
    display: block;
}
.img-tit.nev {
margin-top: -20px;
}
.page-bottom {
height:420px;		
}
.diclamer {font-size:10px;}
.imgcen {text-align:center;}
.ban {width:400px;}
/* =============================================================================
 *  резиновая таблица
 * ========================================================================== */
.responsive-table {
  width: 100%;
    display: table;
}
.responsive-table thead {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
.responsive-table thead th {
  background-color: #e6edf0;
    border: 1px solid #c1c6c7;
  text-align: center;
}
.responsive-table thead th:first-of-type {
/*   text-align: left; */
}
.responsive-table tbody,
.responsive-table tr,
.responsive-table th,
.responsive-table td {
  display: block;
  padding: 0;
  text-align: left;
  white-space: normal;
  transition:all 0.3s ease-in-out 0s;
}

.responsive-table th,
.responsive-table td {
  padding: .5em;
  vertical-align: middle;
  	border: 1px solid #c1c6c7; /*границы*/
}
.responsive-table caption {
/*    margin: 1.5em 0 0.5em; */
  font-size: 1em;
  font-weight: bold;
  text-align: center;
}
.responsive-table tfoot {
  font-size: .8em;
  font-style: italic;
}
.responsive-table tbody tr {
  margin-bottom: 1em;
  border: 2px solid #c1c6c7;
}
.responsive-table tbody tr:last-of-type {
  margin-bottom: 0;
}
.responsive-table tbody th[scope="row"] {
  background-color: #c1c6c7;
  color: white;
}
.responsive-table tbody td[data-type=currency] {
  text-align: right;
}
.responsive-table tbody td[data-title]:before {
  content: attr(data-title);
  float: left;
  font-size: .8em;
}
.responsive-table tbody td {
  text-align: right;
  border-bottom: 1px solid #c1c6c7;
}

@media (min-width: 75em) {
  .responsive-table th,
  .responsive-table td {
    padding: .75em;
  }
}
@media (min-width: 62em) {
  .responsive-table {
       font-size: 0.8em;
  }
  .responsive-table th,
  .responsive-table td {
    padding: .75em .5em;
  }
  .responsive-table tfoot {
    font-size: .9em;
  }
}
@media (min-width: 52em) {
  .responsive-table {
/*     font-size: .9em; */
  }
  .responsive-table thead {
    position: relative;
    clip: auto;
    height: auto;
    width: auto;
    overflow: auto;
  }
  .responsive-table tr {
    display: table-row;
  }
  .responsive-table th,
  .responsive-table td {
    display: table-cell;
    padding: .5em;
  }
  .responsive-table caption {
    font-size: 1.3em;
  }
  .responsive-table tbody {
    display: table-row-group;
  }
  .responsive-table tbody tr {
    display: table-row;
    border-width: 1px;
  }
  .responsive-table tbody tr:nth-of-type(even) {
    background-color: rgba(94, 93, 82, 0.1);
  }
  .responsive-table tbody th[scope="row"] {
    background-color: transparent;
    color: #5e5d52;
    text-align: left;
  }
  .responsive-table tbody td {
    text-align: center;
  }
  .responsive-table tbody td[data-title]:before {
    content: none;
  }
}
.responsive-table tr:hover, .responsive-table tbody tr:nth-of-type(even):hover {background-color: #b0e3ff;} 


.page__content svg  {margin-right: 8px;}
.mar {margin:20px 0;}
@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials


#main > article > div.page__inner-wrap > section.page__content > p:nth-child(43) > a {word-break: break-all;}